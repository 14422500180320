<template>
    <div class="col-8 pt-4 position-relative" style="min-height: calc(690px - 32px )">
        <div class="water-mark" style="
            background-image: url(/img/logo-bg.93fd2527.png);
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                background-position: center center;
                width: 50%;
                height: 60%;
                z-index: -1;
                opacity: 0.1;
                background-size: contain;
                background-repeat: no-repeat;
        ">
        </div>
        <div class="fs-16 mb-4">Rx:</div>
        <div class="medicine-list mx-4 ">
            <div class="item mb-1" v-for="(medicine, index) in $parent.prescription.medicines">
                <div class="fs-14 d-flex justify-content-between">
                    <div class="d-flex" style="width: 1050px">
                        <div class="sl me-3">{{ index + 1 }}.</div>
                        <div class="name">{{ medicine.medicine }}</div>
                    </div>
                    <div class="d-flex gap-2" style="float: right">
                        <div class="dose flex-shrink-1" style="width: 120px">Dose : <span>{{ medicine.dose }}</span>
                        </div>
                        <div class="days flex-shrink-1" style="width: 120px">Days: <span>{{ medicine.duration }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="position-absolute w-100 bottom-0 start-0">
            <div class="bg-blue d-inline-block fs-12 px-3 py-2 text-white" style="background: #242b6f; font-size:12px; border-radius: 0 20px 20px 0">
                দাঁত তোলার পর করণীয়
            </div>
            <div class="bg-opacity-25 px-3 py-2" style="font-size:8px; background:#35cd5625; color: #35CD56; font-size:12px;">
                *তুলা ৩০ মিনিট পর ফেলতে হবে। *তুলা ফেলানোর পর ক্ষত স্থানে ঠান্ডা কিছূ লাগাতে হবে ।
                *২৪ ঘন্টা ঠান্ডা নরম, মিষ্টি জাতীয় খাবার খেতে হবে ।
                <br>
                *২৪ ঘন্টা পর থেকে কুসুম গরম পানিতে লবণ মিশিয়ে দিনে ৫-১০ বার কুলকুচি করতে হবে । *ঔষধ
                নিয়মিত খাবে ।
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Right"
}
</script>

<style lang="scss" scoped>

</style>
