<template>
    <div id="print-section" style="display: none">
        <Header/>
        <div class="body text-blue">
            <div class="border-top border-bottom border-dark mt-1" style="background-color: #c2a7ae;">
                <div class="container-fluid ">
                    <div class="row">
                        <div class="col-12">
                            <div v-if="prescription.patient" class="d-flex justify-content-between py-1">
                                <div class="fs-16">Name: {{ prescription.patient.full_name }}
                                    <span style="margin-left: 20px;">
                                        ID: {{ $parent.prescription.patient.id_number }}
                                    </span>
                                </div>
                                <div class="fs-16">Sex: {{ prescription.patient.gender }}
                                    <span class="ms-3">Age: {{ prescription.patient.date_of_year }} y
                                        {{ prescription.patient.date_of_month }} m
                                    </span>
                                </div>
                                <div class="fs-16">Date: {{ prescription.date | dateFormat }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="details-section  border-dark ">
                <div class="container-fluid">
                    <div class="row">
                        <left/>
                        <Right/>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Left                from "./Left";
import Right               from "./Right";
import Header              from "./Header";
import Footer              from "./Footer";
import Body                from "./Body";
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";


export default {
    name      : "PrescriptionPrint",
    components: {Body, Footer, Header, Left, Right},
    data      : () => ({
        lists       : [1, 2, 3, 4, 5, 6, 7, 8, 9],
        prescription: {
            date       : '',
            patient_id : '',
            inv        : '',
            bp         : '',
            diabetes   : '',
            temp       : '',
            opg_checked: false,
            patient    : {
                name         : '',
                date_of_year : '',
                date_of_month: '',
                gender       : '',
            },
            doctor     : {
                name : '',
                phone: '',
            },
            items      : [],
            medicines  : [],
        },
    }),
    mounted() {
        //this.getPrescription(this.$route.params.id)
    },
    methods: {
        getItemValue(type) {
            let found = this.prescription.items.find((o) => o.type === type);
            if (found) {
                return found
            } else {
                return {
                    top_left    : '',
                    top_right   : '',
                    bottom_left : '',
                    bottom_right: '',
                    comment     : ''
                }
            }
        },
        getPrescription(id) {
            ApiService.get(`/prescriptions/${id}`).then((res) => {
                this.prescription = res.data.data;
                setTimeout(() => {
                    this.$htmlToPaper('print-section', {
                        styles: [
                            'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css',
                            '/../css/_prescription.css',
                        ]
                    });
                }, 1000)

            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
    }
}
</script>

<style scoped>
</style>
