<template>
    <div class="body text-blue">
        <div class="border-top border-bottom border-dark mt-3" style="background: #c2a7ae;">
            <div class="container-fluid ">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between py-2">
                            <div class="fs-16">Name: AR RAHMAN</div>
                            <div class="fs-16">Sex: Male <span class="ms-3">Age: 27</span></div>
                            <div class="fs-16">Date: 24-03-2022</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="details-section  border-dark ">
            <div class="container-fluid">
                <div class="row  min-vh-50">
                    <left/>
                    <Right/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Left  from "./Left";
import Right from "./Right";
export default {
    name: "Body",
    components: {Right, Left}
}
</script>

<style scoped>

</style>
