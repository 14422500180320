<template>
    <div class="header">
        <div class="container-fluid">
            <div class="row text-primary">
                <div class="col-12">
                    <div class="d-flex align-items-center justify-content-center mt-5 mb-2">
                        <div class="logo me-2" style="width: 100px;">
                          <img style="height: 80px" class="img img-responsive" src="@/assets/logo.png" alt="logo">
                        </div>
                        <div class="name">
                            <h3 style="font-size: 41px; letter-spacing: 5px; color: #35CD56; margin-bottom: 0;line-height: 20px">পেইন কিউর ডেন্টাল কেয়ার</h3>
                            <h3 class="text-blue" style="font-size: 41px; letter-spacing: 7px; color: #242b6f">Pain Cure Dental Care</h3>
                        </div>
                    </div>
                    <div class="address text-center fs-12 mb-2">
                        <p style="font-size: 12px; color: #35CD56; margin-bottom: 0">722/4, West Shewrapara, Mirpur, Dhaka-1216, In Front of East West Int. School and
                            College</p>
                        <p style="font-size: 12px; color: #35CD56">website: paincuredentalcare.com, Facebook: https://www.facebook.com/paincuredentalcare</p>
                    </div>
                </div>
                <div class="col-6">
                    <h5 class="text-blue"  style="font-size: 14px; color: #242b6f">{{ $parent.prescription.doctor.name }}</h5>
                    <div class="fs-12" style="font-size: 12px; color: #35CD56;white-space: pre;min-height: 110px;max-height: 110px;overflow: hidden;">
                        {{ $parent.prescription.doctor.doctor_info }}
                    </div>
                </div>
                <div class="col-3 mt-3">
                    <div class="fs-14" style="font-size: 12px; color: #35CD56">
                        Visiting Hour: <br>{{ $parent.prescription.doctor.doctor_opening }}
                    </div>
                </div>
                <div class="col-3 text-end text-center">
                    <h5 class="text-blue" style="font-size: 14px; color: #242b6f">Chamber Open:</h5>
                    <div class="fs-12 fw-bold" style="font-size: 12px; color: #35CD56">
                        10:00 AM to 1:00 PM<br>
                        5:00 PM to 10:00 PM <br>
                        Friday Close
                    </div>
                </div>
                <div class="col-4 d-none" >
                    <h5 class="text-blue" style="font-size: 14px; color: #242b6f">Dental Surgeon</h5>
                    <div class="fs-12">
                        BMDC Reg.No: 9251 <br>
                        Mobile: {{ $parent.prescription.doctor.phone }} <br>
                    </div>
                </div>

                <div class="col-6 text-end pe-5 mt-3 d-none">
                    <div class="fs-16" style="font-size: 12px; color: #35CD56">
                        Patient ID: <span class="border px-5 py-1">{{ $parent.prescription.patient.id_number }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Header"
}
</script>

<style scoped>

</style>
