<template>
    <div class="footer divFooter">
        <div class="container-fluid">
            <div class="row">
                <div class="col-4 border-top border-end border-dark  py-3" style="background: #98a7c4;">
                    <div class="fs-12 text-blue" style="font-size:12px;color: #242b6f">
                        প্রতি ৬ মাস অন্তর অন্তর আপনার মূখ ও দাঁত দন্তরোগ বিশেষজ্ঞ দ্বারা পরীক্ষা করুন ।
                    </div>
                </div>
                <div class="col-8 pt-3" style="background: #35CD56">
                    <div class="fs-12 text-white" style="font-size:12px;">
                        পরবর্তী স্বাক্ষাতঃ <span v-if="$parent.prescription.next_visit">{{ $parent.prescription.next_visit | dateTimeFormat }}</span> <span v-else>.............দিন পর</span> । স্বাক্ষাতের সময় ব্যবস্থাপত্র সঙ্গে আনবেন ।<br>
                        হটলাইনঃ ০১৭২০৪২৫৪৮৫, ০১৭৭২১০২২৩৩ (সিরিয়ালের জন্য) ০১৭০৯২২২২৯৮ ( হোয়াটসআপ )
                    </div>
                    <p style="
            font-size: 9px;
            text-align: center;
            font-weight: bold;
            color: #fff;
            margin: 8px 0 ;
            "
                    >
                        Powered by  <img class="mx-2" style="width: 65px" src="@/assets/softart.png" alt="">
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style scoped>

</style>
