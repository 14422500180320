<template>
    <div class="item">
        <div class="d-flex justify-content-between align-items-center">
            <p>X-Ray :</p>
            <div class="align-items-center d-flex flex-wrap justify-content-center position-relative flex-shrink-0"
                style="font-size: 12px">
                <table class="custom-table">
                    <tr style="border-bottom: 1px solid #000;height: 10px;">
                        <td style="border-right: 1px solid #000;text-align: center;width: 10px;">
                            {{ item.top_left }}
                        </td>
                        <td style="text-align: center;width: 10px;">
                            {{ item.top_right }}
                        </td>
                    </tr>
                    <tr style="height: 10px;">
                        <td style="border-right: 1px solid #000;text-align: center;width: 10px;">
                            {{ item.bottom_left }}
                        </td>
                        <td>
                            {{ item.bottom_right }}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : "LeftItemXRay",
    props: {
        title: {type: String},
        item : {
            type: Object,
        }
    }
}
</script>

<style scoped>
.custom-table tr {
    border-bottom: 1px solid #000;
    height: 10px;
}

.custom-table tr:last-child {
    border-bottom: 0;
}

.custom-table tr td {
    border-right: 1px solid #000;
    text-align: center;
    width: 10px;
}

.custom-table tr td:last-child {
    border-right: 0;
}
</style>
