<template>
    <div class="col-4 ps-3 py-2 border-end border-dark" style="background: #98a7c4;">
        <LeftItem title="C/C" :item="getItemValue('cc')"/>
        <LeftItem title="O/E" :item="getItemValue('oe')"/>
        <!--        <LeftItem title="X-Ray" :item="getItemValue('x-ray')"/>-->
        <div class="d-flex align-items-center justify-content-between">
            <LeftItemXRay title="X-Ray" :item="getItemValue('x-ray')"/>
            <div class="item">
                <div class="d-flex justify-content-between align-items-center">
                    <p class="mb-0 me-3">OPG:</p>
                    <div class="custom-checkbox position-relative">
                        <input v-if="$parent.prescription.opg_checked === 1" checked="checked" style="width: 20px; height: 20px;pointer-events: none;" class="pointer-event " id="opg" type="checkbox">
                        <input v-if="$parent.prescription.opg_checked === 0" style="width: 20px; height: 20px;pointer-events: none;" class="pointer-event " id="opg" type="checkbox">
                        <label for="opg"></label>
                    </div>
                </div>
            </div>
        </div>
        <LeftItem title="Adv" :item="getItemValue('adv')"/>
        <LeftItem title="Treatment Plan" :item="getItemValue('treatment_plan')"/>
        <div class="item mb-1">
            <p class="mb-0">Inv:<span class="ms-3 fs-12">{{ $parent.prescription.inv }}</span></p>
        </div>
        <div class="item mb-1">
            <p class="mb-0">Bp:<span class="ms-3 fs-12">{{ $parent.prescription.bp }}</span></p>
        </div>
        <div class="item mb-1">
            <p class="mb-0">Diabetes:<span class="ms-3 fs-12">{{ $parent.prescription.diabetes }}</span></p>
        </div>
        <div class="item mb-1">
            <p class="mb-0">Temp: {{ $parent.prescription.temp }}</p>
        </div>
        <div class="item mb-1">
            <p class="mb-0">Pre: Disease History- {{ $parent.prescription.patient ? $parent.prescription.patient.disease :''}}</p>
        </div>
        <div class="item mb-1">
            <p class="mb-0">Pre: Medicine History- {{ $parent.prescription.patient ? $parent.prescription.patient.medicines :''}}</p>
        </div>
    </div>
</template>

<script>
import LeftItem     from "./LeftItem";
import LeftItemXRay from "./LeftItemXRay";

export default {
    name      : "Left",
    components: {LeftItemXRay, LeftItem},
    methods   : {
        getItemValue(type) {
            let found = this.$parent.prescription.items.find((o) => o.type === type);
            if (found) {
                return found
            } else {
                return {
                    top_left    : '',
                    top_right   : '',
                    bottom_left : '',
                    bottom_right: '',
                    comment     : ''
                }
            }
        },
    }
}
</script>

<style scoped>

</style>
